<template>
  <div>
    <template v-if="activeTaskType.type === 'approvement_request'">
      <h1>Ваш заказ сформирован и ожидает подтверждения!</h1>
      <hr />
      <p>
        <b
          >Cтоимость полученных Вами призов превысила освобожденную
          от&nbsp;обложения НДФЛ сумму, установленную в&nbsp;п. 28 ст. 217
          НК&nbsp;РФ&nbsp;(в&nbsp;размере 4000&nbsp;рублей).</b
        >
      </p>
      <p>
        Для того, чтобы заказ был обработан необходимо заполнить форму
        подтверждения личности.
      </p>
      <a href="#/verification" class="button button--large">Заполнить форму</a>
      <br />
      <br />
    </template>
    <template v-else>
      <template v-if="!isUserApproved">
        <h1>Ваш заказ сформирован и ожидает подтверждения!</h1>
        <h2>
          После подтверждения Вашей личности Вам придет уведомление о
          необходимости подписания акта для получения сертификатов
        </h2>
      </template>
      <template v-else>
        <h1>
          Стоимость полученных Вами призов превысила освобожденную
          от&nbsp;обложения НДФЛ сумму
        </h1>
        <hr />
        <br />

        <template v-if="activeTaskType.signing_method === 'manual'">
          <p>
            Уважаемый участник мотивационной Программы &laquo;Портал &laquo;{{
              this.$store.state.main.title
            }}&raquo;,
            <b
              >стоимость полученных Вами призов превысила освобожденную
              от&nbsp;обложения НДФЛ сумму, установленную в&nbsp;п. 28 ст. 217
              НК&nbsp;РФ&nbsp;(в&nbsp;размере 4000&nbsp;рублей).</b
            >
          </p>
          <p>
            Представитель Организатора мероприятия, по&nbsp;Вашему поручению,
            как Победителя, в&nbsp;соответствии со&nbsp;ст. 226
            НК&nbsp;РФ&nbsp;удерживает и&nbsp;перечисляет в&nbsp;бюджет налог
            на&nbsp;доходы физического лица (НДФЛ), который взимается
            по&nbsp;ставке 13%. Никаких иных налоговых обязанностей в&nbsp;связи
            с&nbsp;получением призов у&nbsp;Вас, как у&nbsp;Победителя,
            не&nbsp;возникает, налог уплачивается за&nbsp;счет Организатора
            Программы.
          </p>
          <p>
            Подписывая настоящий Акт, Вы&nbsp;соглашаетесь на&nbsp;удержание,
            перечисление в&nbsp;бюджет НДФЛ и&nbsp;подтверждаете достоверность
            и&nbsp;принадлежность Вам указанных в&nbsp;Акте персональных
            и&nbsp;иных данных. В&nbsp;соответствии с&nbsp;Правилами Программы
            Вы&nbsp;обязаны подписать Акт и&nbsp;загрузить его на&nbsp;<a
              :href="`#/upload/${activeTaskType.id}`"
              >специальной странице загрузки</a
            >
            <b>в&nbsp;течение 5 рабочих дней со&nbsp;дня получения Акта</b>,
            а&nbsp;оригинал направить почтовым отправлением (или лично)
            по&nbsp;почтовому адресу: 123458, г.&nbsp;Москва,
            ул.&nbsp;Твардовского, д.8, стр.&nbsp;1, Технопарк
            &laquo;Строгино&raquo;, корпус &laquo;Г&raquo;, офис
            &#8470;&nbsp;313. В&nbsp;противном случае выдача призов
            на&nbsp;<nobr>промо-сайте</nobr> для Вас не&nbsp;будет возобновлена.
          </p>
          <p>
            <b
              >Вам необходимо
              <a
                :href="`#/download-act/${activeTaskType.meta.year}/${activeTaskType.id}`"
                >скачать акт</a
              >, подписать его и отправить по&nbsp;<a
                :href="`#/upload/${activeTaskType.id}`"
                >ссылке</a
              >.</b
            >
          </p>
        </template>
        <template v-else>
          <p>
            Уважаемый участник мотивационной Программы &laquo;Портал &laquo;{{
              this.$store.state.main.title
            }}&raquo;,
            <b
              >стоимость полученных Вами призов превысила освобожденную
              от&nbsp;обложения НДФЛ сумму, установленную в&nbsp;п. 28 ст. 217
              НК&nbsp;РФ&nbsp;(в&nbsp;размере 4000&nbsp;рублей).</b
            >
          </p>
          <p>
            Представитель Организатора мероприятия, по&nbsp;Вашему поручению,
            как Победителя, в&nbsp;соответствии со&nbsp;ст. 226
            НК&nbsp;РФ&nbsp;удерживает и&nbsp;перечисляет в&nbsp;бюджет налог
            на&nbsp;доходы физического лица (НДФЛ), который взимается
            по&nbsp;ставке 13%. Никаких иных налоговых обязанностей в&nbsp;связи
            с&nbsp;получением призов у&nbsp;Вас, как у&nbsp;Победителя,
            не&nbsp;возникает, налог уплачивается за&nbsp;счет Организатора
            Программы.
          </p>
          <p>
            Подписывая настоящий Акт, Вы&nbsp;соглашаетесь на&nbsp;удержание,
            перечисление в&nbsp;бюджет НДФЛ и&nbsp;подтверждаете достоверность
            и&nbsp;принадлежность Вам указанных в&nbsp;Акте персональных
            и&nbsp;иных данных. В&nbsp;соответствии с&nbsp;Правилами Программы
            Вы&nbsp;обязаны подписать Акт. В&nbsp;противном случае выдача призов
            на&nbsp;промо-сайте для Вас не&nbsp;будет возобновлена.
          </p>
          <TaskSmsSigning :task-id="activeTaskType.id"/>
        </template>
        <br />
      </template>
    </template>
  </div>
</template>
<script>
import TaskSmsSigning from './TaskSmsSigning.vue';

export default {
  name: "OrderPrevent",
  computed: {
    activeTasks() {
      return this.$store.getters["tasks/active"];
    },
    isUserApproved() {
      return this.$store.getters["tasks/isUserApproved"];
    },
    activeTaskType() {
      const approvementRequestTask = this.activeTasks.find(
        (task) => task.type === "approvement_request",
      );

      if (approvementRequestTask) {
        return approvementRequestTask;
      }

      return this.activeTasks.find((task) => task.type === "act_request");
    },
  },
  async beforeMount() {
    await this.$store.dispatch("tasks/get");
  },
};
</script>
