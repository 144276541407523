<template>
  <div>
    <div v-if="signingLinkError">
      <p>
        <b>{{ signingLinkError }}</b>
      </p>
    </div>
    <p v-else-if="!signingLink">
      <b>Пожалуйста подождите. Ожидаем ссылку на подписание...</b>
    </p>
    <p v-else>
      <b
        ><a target="_blank" class="text--red" :href="signingLink"
          >Ссылка на&nbsp;страницу подписания Акта</a
        ></b
      >
    </p>
  </div>
</template>
<script>
import taskApi from "../api/task";

export default {
  props: ["taskId"],
  data: () => ({
    signingLink: "",
    interval: null,
    signingLinkError: "",
  }),
  methods: {
    async initSigningLinkPulling() {
      this.signingLinkError = "";
      this.interval = setInterval(async () => {
        try {
          const { data: { status, link } } = await taskApi.getSigningLink(
            this.$store,
            this.taskId,
          );
          if (link) {
            this.signingLink = link;
            clearInterval(this.interval);
          }
        } catch (error) {
          console.error(error)
          if (
            !error?.response || 
            error.response.statusText !== "contract_not_found"
          ) {
            this.signingLinkError =
              "Произошла ошибка при получении ссылки на подписание.";
            clearInterval(this.interval);
          }
        }
      }, 10000);
    },
  },
  mounted() {
    this.initSigningLinkPulling();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
<style lang="css" scoped>
.text--red {
  color: #900;
}
</style>