<template>
  <div>
    <OrderPrevent v-if="preventComponent" ></OrderPrevent>
    <div v-else>
      <h1>{{ textTitle }}</h1>
      <div class="alert alert--danger" v-if="error">
        <p v-html="error"></p>
      </div>
      <div class="alert alert--success" v-if="success">
        <p v-html="success"></p>
      </div>
      <div v-if="error === '' && success === ''">
        <div class="alert alert--danger" v-if="$store.state.user.balance - $store.getters['cart/sum'] < 0" v-html="getErrorMessage('insufficent_funds')"></div>
        <div class="alert alert--danger" v-if="showSpendError" v-html="getErrorMessage('spend_all_points')"></div>
        <slot></slot>
        <div v-if="$store.state.user.balance - $store.getters['cart/sum'] >= 0 && !showSpendError">
          <div v-if="sent === false">
            <div v-if="$store.state.cart.items.length == 0">
              <p>{{ textEmptyCart }}</p>
            </div>
            <div v-if="$store.state.cart.items.length > 0">
              <h2>{{ textYourCart }}</h2>
              <table class="order__cart" v-if="Object.values($store.state.order.products).length > 0">
                <thead>
                  <tr>
                    <td class="order__cart-item-col">Продукт</td>
                    <td class="order__cart-item-col">Описание</td>
                    <td class="order__cart-item-col order__cart-item-col--points">Стоимость, баллов</td>
                  </tr>
                </thead>
                <tfoot>
                  <tr>
                    <td class="order__cart-item-col" colspan="2">{{ textRemainsAfterOrder }}</td>
                    <td class="order__cart-item-col order__cart-item-col--points"><strong>{{ $store.state.user.balance - $store.getters['cart/sum'] }}</strong></td>
                  </tr>
                </tfoot>
                <tbody>
                  <tr class="order__cart-item" v-for="(item, index) in $store.state.cart.items" :key="index">
                    <td class="order__cart-item-col order__cart-item-col--id" data-title="Продукт">
                      <span>{{ $store.state.order.products[item.id].title }}</span>
                    </td>
                    <td class="order__cart-item-col" data-title="Описание">
                      <span>Номинал: <strong>{{ item.price.face ? item.price.face : item.price.points }} {{ currentCurrency }}</strong></span>
                      <br>
                      <span v-if="item.price.alt">{{ item.price.alt }}</span>
                    </td>
                    <td class="order__cart-item-col order__cart-item-col--points" data-title="Стоимость, баллов">
                      <strong>{{ item.price.points }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-10 col-md-9 col-lg-8">
              <form class="form">
                <div class="alert alert--danger" v-if="errorSend">
                  <p v-html="errorSend"></p>
                </div>
                <div v-if="sent === false && $store.state.cart.items.length > 0">
                  <p v-if="cartProductsTypes.includes('giftery')">{{ textFillTheForm }}</p>
                  <div class="form-group" v-if="cartProductsTypes.includes('giftery')">
                    <input
                      type="email"
                      name="email"
                      class="form-field form-field--large"
                      placeholder="Email"
                      v-model="email"
                    >
                  </div>
                  <slot name="pre-button-text"></slot>
                  <button class="button button--rounded button--large" @click.prevent="submit()" :disabled="sending">Оформить</button>
                  <slot name="go-to"></slot>
                </div>
              </form>
            </div>
          </div>
        </div>
        <slot name="clear-cart"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import OrderPrevent from './OrderPrevent.vue'

import order from '../api/order'
import catalog from '../api/catalog'
import appError from 'base/modules/app-error'
import utils from '../modules/utils'
import { currency } from '../modules/constants'

const storeModule = {
  namespaced: true,
  state: {
    products: '',
    email: ''
  },
  mutations: {
    updateProducts: function (state, payload) {
      state.products = payload
    },
    updateEmail: function (state, payload) {
      state.email = payload
    }
  },
  actions: {
    setProducts ({ commit }, payload) {
      commit('updateProducts', payload)
    },
    setEmail ({ commit }, payload) {
      commit('updateEmail', payload)
    }
  }
}

export default {
  name: 'Order',
  props: {
    showSpendError: {
      type: Boolean,
      default: false
    },
    textTitle: {
      type: String,
      default: 'Оформление заказа'
    },
    textEmptyCart: {
      type: String,
      default: 'Ваша корзина пуста'
    },
    textYourCart: {
      type: String,
      default: 'Ваш заказ'
    },
    textRemainsAfterOrder: {
      type: String,
      default: 'После оформления заказа у вас останется:'
    },
    textFillTheForm: {
      type: String,
      default: 'Заполните форму'
    },
    textSuccess: {
      type: String,
      default: 'Ваш заказ успешно оформлен!<br/> Обновите страницу и перейдите в раздел уведомлений'
    }
  },
  data () {
    return {
      sending: false,
      sent: false,
      preventText: '',
      preventComponent: false,
      success: '',
      error: '',
      errorSend: ''
    }
  },
  computed: {
    email: {
      get () {
        return this.$store.state.order.email
      },
      set (value) {
        this.$store.dispatch('order/setEmail', value)
      }
    },
    cartProductsTypes: function () {
      return this.$store.state.cart.items.map(item => {
        if (typeof this.$store.state.order.products[item.id] !== 'undefined' && typeof this.$store.state.order.products[item.id].type !== 'undefined') {
          return this.$store.state.order.products[item.id].type
        } else {
          return null
        }
      }).filter(item => item !== null)
    },
    region () {
      return this.$store.getters['main/region']
    },
    currentCurrency () {
      return currency[this.region]
    },
  },
  methods: {
    getErrorMessage: code => {
      return appError.getMessage(code)
    },
    beforeMake: function (cart) {
      Vue.$logger.debug('Order | Before make order')
      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    afterMake: function (cart) {
      Vue.$logger.debug('Order | After make order')
      return new Promise((resolve, reject) => {
        resolve()
      })
    },
    submit: function () {
      this.beforeMake(this.$store.state.cart.items).then(() => {
        this.errorSend = ''

        if (this.cartProductsTypes.includes('giftery')) {
          if (!utils.validateEmail(this.$store.state.order.email)) {
            this.errorSend += '<p>Некорректный email</p>'
          }
        }

        if (this.errorSend === '') {
          this.sending = true

          this.$store.state.cart.items.forEach(async item => {
            try {
              await order.make(this, {
                params: {
                  email: this.$store.state.order.email
                },
                cart: [item]
              })
              this.success = this.textSuccess
            } catch (error) {
              this.error += appError.getMessage(error.message)

              Vue.$logger.debug('Order | order.make | ошибка: ', error)
            }
          })
          this.sending = false
          this.sent = true
          this.$store.dispatch('cart/clear')
          this.afterMake(this.$store.state.cart.items)
        }
      }).catch(function () {
        Vue.$logger.error('Order | ошибка')
      })
    },
    afterCreated: function () {},
    afterMounted: function () {}
  },
  created: function () {
    if (typeof this.$store._modules.root._children.order === 'undefined') {
      this.$store.registerModule('order', storeModule)
    }

    catalog.get(this).then(catalog => {
      const products = {}
      catalog.products.forEach(product => {
        products[product.id] = product
      })
      this.$store.dispatch('order/setProducts', products)
    }).catch((error) => {
      this.error = appError.getMessage(error.message)

      Vue.$logger.debug('Order | catalog.get | ошибка: ', error)
    })

    this.afterCreated()
  },
  mounted: function () {
    this.afterMounted()
  }
}
</script>

<style lang="css" scoped>
  .order__cart {
    width: 100%;
    border-collapse: collapse;
  }

  .order__cart thead {
    font-weight: bold;
    background: #fafafa;
    text-align: left;
  }

  .order__cart tfoot {
    background: #fafafa;
  }

  .order__cart-item-col {
    padding: 10px 20px;
    border-top: 1px solid #f0f0f0;
  }

  .order__cart-item-col.order__cart-item-col--points {
    text-align: right;
  }

  @media (max-width: 991px) {
    .order__cart-item-col {
      padding: 8px 12px;
    }
  }

  @media (max-width: 767px) {
    .order__cart thead {
      display: none;
    }

    .order__cart tfoot {
      background: none;
    }

    .order__cart tfoot tr td:first-child {
      display: block;
      color: #666;
      font-size: 16px;
      margin-bottom: 6px;
      border-bottom: none;
      padding-bottom: 0;
    }

    .order__cart tfoot tr td:last-child {
      border-top: none;
      padding-top: 0;
    }

    .order__cart-item-col {
      display: block;
      text-align: left !important;
      font-size: 22px;
      padding-left: 0;
      padding-right: 0;
    }

    .order__cart-item-col:before {
      content: attr(data-title);
      display: block;
      color: #666;
      font-size: 16px;
      margin-bottom: 6px;
    }
  }

  @media (max-width: 479px) {
    .order__cart-item-col {
      font-size: 18px;
    }

    .order__cart-item-col:before {
      font-size: 14px;
    }

    .order__cart tfoot tr td:first-child {
      font-size: 14px;
    }
  }

  .form {
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 6px;
  }

  .form-group input {
    width: 100%;
  }

  button {
    margin-bottom: 10px;
  }
</style>
