import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  make (app, order) {
    Vue.$logger.info('order | Making order', JSON.parse(JSON.stringify(order)))

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/order'

    return axios.post(ACTION_URL, order, { headers: app.$store.getters['user/authHeader']() })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('order', order)
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('order | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('order_error')
          } else if (error.response.status === 415) {
            if (typeof error.response.data !== 'undefined' && typeof error.response.data.message !== 'undefined') {
              throw new Error(error.response.data.message.replace(/\s/gi, '_'))
            } else {
              throw new Error('order_error')
            }
          } else {
            throw new Error('order_error')
          }
        } else if (error.request) {
          throw new Error('network_error')
        } else {
          throw new Error('general_error')
        }
      })
  }
}
