import Vue from 'vue'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

export default {
  get (app) {
    Vue.$logger.debug('catalog | Trying to get catalog')

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/catalog'

    return axios.get(ACTION_URL, { headers: app.$store.getters['user/authHeader']() })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('catalog | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('catalog_error')
          } else {
            throw new Error('catalog_error')
          }
        } else if (error.request) {
          Vue.$logger.error('catalog', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  },
  getExchangeProductsById (app, id) {
    Vue.$logger.debug('catalog | Trying to get exchange products for ' + id)

    const ACTION_URL = process.env.VUE_APP_REWARDS_API_URL + '/' + app.$store.state.main.code + '/user/exchange/' + id

    return axios.get(ACTION_URL, { headers: app.$store.getters['user/authHeader']() })
      .then((response) => {
        return response.data
      }).catch((error) => {
        Sentry.setExtra('error', error)
        Sentry.setExtra('response', error.response)
        if (error.response) {
          Sentry.setExtra('response.status', error.response.status)

          if (typeof error.response.data !== 'undefined') {
            Sentry.setExtra('response.data', JSON.stringify(error.response.data))
          }
        }
        Sentry.captureException(error)
        if (error.response) {
          Vue.$logger.error('catalog | ' + error.response.status + ' ' + error.response.statusText)
          if (error.response.status >= 500) {
            throw new Error('server_error')
          } else if (error.response.status === 401) {
            throw new Error('not_authorized')
          } else if (error.response.status === 404) {
            throw new Error('catalog_error')
          } else {
            throw new Error('catalog_error')
          }
        } else if (error.request) {
          Vue.$logger.error('catalog', error.request)
          throw new Error('network_error')
        } else {
          Vue.$logger.debug('Error', error.message)
          throw new Error('general_error')
        }
      })
  }
}
