<template>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9 col-xl-8">
          <div class="container__page">
            <div class="container__content">
              <ThisComponent
                text-title="Оформление заказа"
                text-empty-cart="Корзина пуста"
                text-your-cart=""
                text-remains-after-order="После оформления заказа останется:"
                text-fill-the-form="Укажи свой email"
                :text-success="'Твой заказ успешно оформлен.<br>В течение 24 часов ты получишь на почту свой сертификат.'"
              >
                <template #pre-button-text>
                  <div style="padding-top: 10px; padding-bottom: 10px; font-weight: bold; color: rgb(235, 90, 64);">Обрати внимание, что после оформления заказа изменить категорию будет невозможно.</div>
                  <div style="padding-top: 10px; padding-bottom: 18px; color: #999; font-size: 80%;">Не забывай, что подарки от работодателя свыше 4000 за год облагаются НДФЛ.</div>
                </template>
              </ThisComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Vue from 'vue'
import ThisComponent from 'base/components/Order'

ThisComponent.mounted = function () {
  this.$store.commit('app/updatePageClass', 'page--order')

  this.afterMounted()
}

ThisComponent.methods.beforeMake = function (cart) {
  Vue.$logger.debug('Order | Before make order from Component')
  return new Promise((resolve, reject) => {
    resolve()
  })
}

ThisComponent.methods.afterMake = function () {
  this.$store.dispatch('user/getBalance')

  Vue.$logger.debug('Order | After make from Component')
}

export default {
  components: {
    ThisComponent
  }
}
</script>
